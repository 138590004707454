import React, { useState, useEffect, useRef } from "react";
import { ethers, Contract } from "ethers";
import { formatEther } from "ethers";
import WalletConnectButton from './WalletConnectButton'; // اضافه کردن کامپوننت WalletConnect
import DiceRollingGameABI from './DiceRollingGame.json';
import './App.css';
import VantaBackground from './VantaBackground';
import HowToPlay from './HowToPlay';
import shine from './pic/shine.svg';
import rool from './pic/rool.svg';
import rolling from './pic/rolling.svg';
import bag from './pic/bag.svg'; // اضافه کردن تصویر bag.svg
import handGif from './pic/hand.gif'; // بارگذاری تصویر
import heartIcon from './pic/heart.png'; // بارگذاری آیکون قلب
import instagramIcon from './pic/instagram.png'; // بارگذاری آیکون اینستاگرام
import telegramIcon from './pic/telegram.png'; // بارگذاری آیکون تلگرام
import socialxIcon from './pic/socialx.png'; // بارگذاری آیکون سوشیال ایکس
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DiceAnimation from './DiceAnimation'; // Import the new DiceAnimation component
import './DiceAnimation.css'; // Import the CSS styles for the dice animation
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom'; // Import Router components

// آدرس قرارداد در شبکه تست BSC
const contractAddress = '0x0B9dA018A83A4BacE855655e103c3b50109426e9'; // آدرس قرارداد خود را اینجا قرار دهید
const walletAddress = '0xC776f5fDB11eC7897cbc18a4005390eb1D7DeC62'; // آدرس کیف پول

function App() {
    const [provider, setProvider] = useState(null);
    const [signer, setSigner] = useState(null);
    const [totalPool, setTotalPool] = useState(0);
    const [participantsCount, setParticipantsCount] = useState(0);
    const [isWalletConnected, setIsWalletConnected] = useState(false);
    const [walletAddressState, setWalletAddress] = useState('');
    const [diceResult, setDiceResult] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showHowToPlay, setShowHowToPlay] = useState(false);
    const [userInGame, setUserInGame] = useState(false);
    const [userStatus, setUserStatus] = useState({
        isInGame: false,
        hasCreatedDice: false,
        diceStatus: 'Normal',
        hasRolledDice: false,
        userGroup: 0,
        userPrizeAmount: 0
    });

    const vantaRef = useRef(null);
    const modalRef = useRef(null);
    const { walletAddress: paramWalletAddress } = useParams(); // دریافت آدرس کیف پول از URL

    useEffect(() => {
        if (paramWalletAddress) {
            handleWalletConnect(paramWalletAddress); // اتصال به کیف پول با آدرس دریافت شده
        }
    }, [paramWalletAddress]);

    const getRandomNumber = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    useEffect(() => {
        if (window.VANTA) {
            vantaRef.current = window.VANTA.DOTS({
                el: "#vanta-background",
                mouseControls: true,
                touchControls: true,
                gyroControls: false,
                minHeight: 200.00,
                minWidth: 200.00,
                scale: 1.00,
                scaleMobile: 1.00,
                color: 0x4d4dfc,
                color2: 0x7961d2
            });
        } else {
            console.error("VANTA is not loaded.");
        }

        return () => {
            if (vantaRef.current) {
                vantaRef.current.destroy();
                vantaRef.current = null;
            }
        };
    }, []);

    useEffect(() => {
        if (!window.ethereum) {
            toast.error('Please install MetaMask. If you are using an Android or iOS device, enter the app address in the wallet’s internal browser to be able to play the game.');
        } else {
            // تغییر شبکه به BSC Mainnet
            window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [{
                    chainId: '0x38', // Chain ID for BSC Mainnet
                    chainName: 'Binance Smart Chain Mainnet',
                    rpcUrls: ['https://bsc-dataseed.binance.org/'],
                    nativeCurrency: {
                        name: 'Binance Coin',
                        symbol: 'BNB',
                        decimals: 18
                    },
                    blockExplorerUrls: ['https://bscscan.com/']
                }]
            }).then(() => {
                toast.success('Switched to Binance Smart Chain Mainnet!');
            }).catch((error) => {
                toast.error('Failed to switch network: ' + error.message);
            });
        }
    }, []);

    useEffect(() => {
        const init = async () => {
            if (!provider) return;

            try {
                const signerInstance = await provider.getSigner();
                setSigner(signerInstance);

                const contract = new Contract(contractAddress, DiceRollingGameABI, signerInstance);
                const pool = await contract.totalPool();
                const totalPoolInBNB = formatEther(pool);
                setTotalPool(totalPoolInBNB);

                const winnersInfo = await contract.getGroupWinnersInfo();
                setParticipantsCount(winnersInfo[0].length); // اصلاح: تفسیر صحیح داده‌های برگشتی
            } catch (error) {
                console.error("Error initializing app:", error);
                toast.error("Failed to initialize the app.pls wait...");
            }
        };

        init();
    }, [provider]);

    const checkUserStatus = async (address) => {
        if (!signer) {
            console.error("Signer is not available.");
            toast.error("Please wait a secend to synchronized app...");
            return;
        }

        const contract = new Contract(contractAddress, DiceRollingGameABI, signer);
        try {
            const status = await contract.getUserStatus();
            setUserStatus({
                isInGame: status.isInGame,
                hasCreatedDice: status.hasCreatedDice,
                diceStatus: status.diceStatus,
                hasRolledDice: status.hasRolledDice,
                userGroup: status.userGroup,
                userPrizeAmount: formatEther(status.userPrizeAmount)
            });

            setUserInGame(status.isInGame);
        } catch (error) {
            console.error("Error checking user status:", error);
            toast.error("Failed to check user status. Please try again.");
        }
    };

    const handleWalletConnect = (account) => {
        setWalletAddress(account);
        setIsWalletConnected(true);
        const newProvider = new ethers.BrowserProvider(window.ethereum);
        setProvider(newProvider);
        newProvider.getSigner().then((signer) => setSigner(signer));
        checkUserStatus(account);
    };

    const joinGame = async (e) => {
        e.preventDefault();

        if (!signer) {
            toast.error('Please connect your wallet first.');
            return;
        }

        setLoading(true);

        try {
            const contract = new Contract(contractAddress, DiceRollingGameABI, signer);
            const tx = await contract.joinGame({ value: ethers.parseEther('0.004') });
            await tx.wait();

            await checkUserStatus(walletAddressState);
            toast.success("You have successfully joined the game!");
        } catch (error) {
            console.error("Transaction failed:", error);
            handleTransactionError(error);
        } finally {
            setLoading(false);
        }
    };

    const createDice = async () => {
        if (!signer) {
            toast.error('Please connect your wallet first.');
            return;
        }

        setLoading(true);

        try {
            const contract = new Contract(contractAddress, DiceRollingGameABI, signer);
            const userStatus = await contract.users(walletAddressState);

            if (!userStatus.exists) {
                toast.error("You are not in the game.");
                return;
            }
            if (userStatus.createdDice) {
                toast.error("You have already created a dice.");
                return;
            }

            const tx = await contract.createDice();
            await tx.wait();

            await checkUserStatus(walletAddressState);
            toast.success("Dice created successfully.");
        } catch (error) {
            console.error("Transaction failed:", error);
            handleTransactionError(error);
        } finally {
            setLoading(false);
        }
    };

    const handleDiceClick = async () => {
        if (!signer) {
            toast.error('Please connect your wallet first.');
            return;
        }

        setLoading(true);

        try {
            const contract = new Contract(contractAddress, DiceRollingGameABI, signer);
            
            // تولید یک عدد تصادفی با استفاده از crypto.getRandomValues
            const randomValues = new Uint32Array(1);
            let seed;

            // حلقه برای اطمینان از اینکه عدد تصادفی صفر نباشد
            do {
                window.crypto.getRandomValues(randomValues);
                seed = randomValues[0] % 10000; // عدد تصادفی بین 0 تا 9999
            } while (seed === 0); // تکرار تا زمانی که عدد صفر نباشد

            const tx = await contract.rollDice(seed);
            await tx.wait(); // منتظر ماندن تا تراکنش تایید شود

            await checkUserStatus(walletAddressState);
            toast.success("Dice rolled successfully!");
        } catch (error) {
            console.error("Transaction failed:", error);
            handleTransactionError(error);
        } finally {
            setLoading(false);
        }
    };

    const withdrawPrize = async () => {
        if (!signer) {
            toast.error('Please connect your wallet first.');
            return;
        }

        setLoading(true);

        try {
            const contract = new Contract(contractAddress, DiceRollingGameABI, signer);
            const tx = await contract.withdrawPrize();
            await tx.wait();

            await checkUserStatus(walletAddressState);
            toast.success("Prize withdrawn successfully.");
        } catch (error) {
            console.error("Transaction failed:", error);
            handleTransactionError(error);
        } finally {
            setLoading(false);
        }
    };

    const handleTransactionError = (error) => {
        if (error.code === 4001) {
            toast.error("Operation rejected by the user."); // Show error toast
        } else {
            const filteredMessage = error.message.match(/"([^"]+)"/);
            toast.error(filteredMessage ? filteredMessage[1] : 'Transaction failed. Please try again.'); // Show error toast
        }
    };

    const closeHowToPlay = () => {
        setShowHowToPlay(false);
        document.body.style.overflow = 'auto';
    };

    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            closeHowToPlay();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(walletAddress).then(() => {
            toast.success("Wallet address copied to clipboard!");
        }).catch(err => {
            toast.error("Failed to copy wallet address.");
            console.error('Could not copy text: ', err);
        });
    };

    return (
        <div className="app-container">
            <ToastContainer />
            <div id="vanta-background" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: -1 }} />
            <h1>Dice Rolling Game</h1>
            <button onClick={() => {
                setShowHowToPlay(true);
                document.body.style.overflow = 'hidden';
            }} className="how-to-play-button">How to Play?</button>

            {!isWalletConnected ? (
                <button className="connect-wallet-button" onClick={() => handleWalletConnect(walletAddress)}>Connect Wallet</button>
            ) : (
                <div className="game-container">
                    <h2 className="wallet-address">Wallet Address: {walletAddressState}</h2>
                    {userInGame && <p>You are in the game.</p>}
                    <div className="button-group">
    <div className="tooltip">
        <button className={`game-button ${loading ? "disabled-button" : ""}`} onClick={joinGame} disabled={loading}>
            <img src={shine} alt="Join Game" />
            <span style={{ fontSize: '8px', color: 'red', position: 'absolute', top: '50px', left: '60px' }}>join game</span>
        </button>
    </div>
    <div className="tooltip">
        <button 
            className={`game-button ${loading ? "disabled-button" : ""}`} 
            onClick={createDice} 
            disabled={loading}
        >
            <img src={rool} alt="Create Dice" />
            <span style={{ fontSize: '8px', color: 'red', position: 'absolute', top: '50px', left: '30px' }}>create your dice</span>
        </button>
    </div>
</div>
<DiceAnimation onRollComplete={() => {}} result={diceResult} />
<div className="button-group">
    <div className="tooltip">
        <button 
            className={`game-button ${loading ? "disabled-button" : ""}`} 
            onClick={handleDiceClick} 
            disabled={loading}
        >
            <img src={rolling} alt="Roll Dice" style={{ width: '100px', height: '100px' }} />
            <span style={{ fontSize: '8px', color: 'red', position: 'absolute', top: '50px', left: '60px' }}>roll dice</span>
        </button>
    </div>
    <div className="tooltip">
        <button 
            className={`game-button ${loading ? "disabled-button" : ""}`} 
            onClick={withdrawPrize} 
            disabled={loading}
        >
            <img src={bag} alt="Withdraw Prize" style={{ width: '100px', height: '100px' }} />
            <span style={{ fontSize: '8px', color: 'red', position: 'absolute', top: '50px', left: '50px' }}>clime prize</span>
        </button>
    </div>
</div>
                    <p>Total Pool: {totalPool} BNB</p>
                    {diceResult !== null && (
                        <div>
                            <h3>Dice Result: {diceResult}</h3>
                        </div>
                    )}
                    {loading && (
                        <div className="loading-container">
                            <img src={handGif} alt="Loading..." className="loading-gif" />
                        </div>
                    )}
                    <div className="user-status">
                        <h3>User Status</h3>
                        <p>In Game: {userStatus.isInGame ? 'Yes' : 'No'}</p>
                        <p>Dice Created: {userStatus.hasCreatedDice ? 'Yes' : 'No'}</p>
                        <p>Dice Status: {userStatus.diceStatus}</p>
                        <p>Dice Rolled: {userStatus.hasRolledDice ? 'Yes' : 'No'}</p>
                        <p>User Group: {userStatus.userGroup}</p>
                        <p>Prize Amount: {userStatus.userPrizeAmount} BNB</p>
                    </div>
                </div>
            )}

            {showHowToPlay && <HowToPlay onClose={closeHowToPlay} ref={modalRef} />}

            {/* دکمه Donate Me */}
            <div className="donate-button" onClick={copyToClipboard}>
                <img src={heartIcon} alt="Donate Me" className="heart-icon" />
                <span className="donate-tooltip">If you want more games, donate me</span>
            </div>

            {/* دکمه‌های شبکه‌های اجتماعی */}
            <div className="social-buttons">
                <a href="https://www.instagram.com/cryptocurrency_dice_roll_bet?igsh=Zjl5d2tpN3QwNjF0" target="_blank" rel="noopener noreferrer">
                    <img src={instagramIcon} alt="Instagram" className="social-icon" />
                </a>
                <a href="https://t.me/lottoariyabot" target="_blank" rel="noopener noreferrer">
                    <img src={telegramIcon} alt="Telegram" className="social-icon" />
                </a>
                <a href="https://x.com/AriyaDice" target="_blank" rel="noopener noreferrer">
                    <img src={socialxIcon} alt="SocialX" className="social-icon" />
                </a>
            </div>
        </div>
    );
}

function Main() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<App />} />
                <Route path="/game/:walletAddress" element={<App />} /> {/* دیپ لینک برای آدرس کیف پول */}
            </Routes>
        </Router>
    );
}

export default Main;