import React, { useState } from 'react';
import detectEthereumProvider from '@metamask/detect-provider';

const WalletConnectButton = ({ onConnect }) => {
    const [account, setAccount] = useState(null);

    const connectWallet = async () => {
        const provider = await detectEthereumProvider();
        if (provider) {
            try {
                const accounts = await provider.request({ method: 'eth_requestAccounts' });
                setAccount(accounts[0]);
                onConnect(accounts[0]);
            } catch (error) {
                console.error('User denied account access', error);
            }
        } else {
            alert('Please install MetaMask!');
        }
    };

    return (
        <button onClick={connectWallet}>
            {account ? `Connected: ${account}` : 'Connect Wallet'}
        </button>
    );
};

export default WalletConnectButton;