import React, { useEffect, useRef, useState } from 'react';
import './DiceAnimation.css'; // Import the CSS styles for the dice animation

const DiceAnimation = ({ onRollComplete, result }) => {
    const diceRef = useRef(null);
    const outputDivRef = useRef(null);
    const [diceValue, setDiceValue] = useState('?'); // وضعیت تاس با علامت سوال

    useEffect(() => {
        // اگر نتیجه از قرارداد هوشمند موجود باشد
        if (result !== null) {
            setDiceValue(result); // تنظیم مقدار تاس به نتیجه دریافتی
            outputDivRef.current.innerHTML = "You've got " + result; // به‌روزرسانی نمایش نتیجه
            outputDivRef.current.classList.remove("reveal");
            outputDivRef.current.classList.add("hide");
            setTimeout(() => {
                outputDivRef.current.classList.add("reveal");
            }, 1500);
        }
    }, [result]);

    useEffect(() => {
        const interval = setInterval(() => {
            const randomSide = Math.floor(Math.random() * 6) + 1; // تولید عدد تصادفی بین 1 تا 6
            diceRef.current.dataset.side = randomSide; // تنظیم ساید تاس
        }, 100); // تغییر ساید تاس هر 100 میلی‌ثانیه

        return () => clearInterval(interval); // پاک کردن interval در هنگام unmount
    }, []);

    const rollDice = () => {
        const result = Math.floor(Math.random() * 6) + 1; // تولید عدد تصادفی بین 1 تا 6
        setDiceValue(result); // تنظیم مقدار تاس به نتیجه تصادفی
        console.log(result);
        
        outputDivRef.current.classList.remove("reveal");
        outputDivRef.current.classList.add("hide");
        outputDivRef.current.innerHTML = "You've got " + result;
        setTimeout(() => {
            outputDivRef.current.classList.add("reveal");
        }, 1500);

        // فراخوانی تابع onRollComplete با نتیجه
        if (onRollComplete) {
            onRollComplete(result);
        }
    };

    return (
        <div className="dice-container">
            <div id="dice" ref={diceRef} onClick={rollDice}>
                <div className="sides side-1"><span className="dice-symbol">{diceValue}</span></div>
                <div className="sides side-2"><span className="dice-symbol">{diceValue}</span></div>
                <div className="sides side-3"><span className="dice-symbol">{diceValue}</span></div>
                <div className="sides side-4"><span className="dice-symbol">{diceValue}</span></div>
                <div className="sides side-5"><span className="dice-symbol">{diceValue}</span></div>
                <div className="sides side-6"><span className="dice-symbol">{diceValue}</span></div>
            </div>
            <div id="diceResult" ref={outputDivRef} className="hide"></div>
        </div>
    );
};

export default DiceAnimation;