import React, { forwardRef, useState } from 'react';

const HowToPlay = forwardRef(({ onClose }, ref) => {
    const [language, setLanguage] = useState('en'); // وضعیت زبان

    const englishContent = (
        <>
            <h2 className="en" style={{ textAlign: 'left' }}>User Guide for the Dice Rolling Game</h2>
            <p className="en">
                Welcome to the Dice Rolling Game! This guide will help you understand the rules, limitations, and mechanics of the game. By following this guide, you can enjoy your gaming experience with confidence.
            </p>
            <p className="en">
                You may complete the game within a period of 2 to 6 days. This depends on your performance in the game.
            </p>
            <p className="en" style={{ fontWeight: 'bold', color: 'red', fontSize: '1.2em' }}>
                Attention
            </p>
            <p className="en">
                Our recommendation is to create a new wallet to enjoy the game and participate by sending the required amount of BNB to that wallet. This is safer for your assets.
            </p>
            <p className="en">
                To join the game you must install a crypto wallet (such as MetaMask, Trust Wallet, etc.)
            </p>
            <p className="en">
                To join the game on Android and iOS devices, you can enter www.lottoariya.xyz in your wallet's internal browser (such as MetaMask, Trust Wallet, etc.) and participate in the game.
            </p>
            <p className="en">
                You need to have approximately 0.006 BNB in your wallet to be able to participate in the game and cover the transaction fees.
            </p>
            <p className="en">
                If you do not create your dice within one day after entering the game and paying 0.004 BNB, you will be removed from the game.
            </p>
            <p className="en">
                If you do not use your dice within 5 days after creating it, you will be removed from the game.
            </p>
            <p className="en">
                If you do not withdraw your prize within one day after rolling the dice and determining the prize, you will be removed from the game.
            </p>
            <h3 className="en" style={{ textAlign: 'left' }}>1. Joining the Game</h3>
            <p className="en">
                <strong>Entry Fee:</strong> To participate, you must pay 0.004 BNB.
            </p>
            <p className="en">
                <strong>Participant Limit:</strong> The game can have up to 1,500 participants. If the limit is reached, you cannot join until a spot becomes available.
            </p>
            <p className="en">
                <strong>One-time Join:</strong> Each user can join only once per game session. You must wait for the next session if you've already joined.
            </p>
            <h3 className="en" style={{ textAlign: 'left' }}>2. Creating the Dice</h3>
            <p className="en">
                <strong>Creation Time:</strong> After joining, you have 1 Day to create your dice.
            </p>
            <p className="en">
                <strong>Previous Dice:</strong> You cannot create a new dice until the current one is used in a roll.
            </p>
            <h3 className="en" style={{ textAlign: 'left' }}>3. Rolling the Dice</h3>
            <p className="en">
                <strong>Rolling Time:</strong> You can roll your dice within 5 Days after creation.
            </p>
            <p className="en">
                <strong>Waiting Before Rolling:</strong> You must wait at least 1 Day after creating the dice before rolling it.
            </p>
            <p className="en">
                <strong>Number of Rolls:</strong> You can roll your dice twice. Each roll generates a random score between 1 and 6.
            </p>
            <h3 className="en" style={{ textAlign: 'left' }}>4. Dice Status</h3>
            <p className="en">
                <strong>Normal Dice:</strong> The initial status when you create your dice.
            </p>
            <p className="en">
                <strong>Silver Dice:</strong> If you wait 2 Days without rolling, your dice turns silver.
            </p>
            <p className="en">
                <strong>Golden Dice:</strong> If you wait another 2 Days after it turns silver, your dice becomes golden.
            </p>
            <p className="en">
                <strong>Impact of Dice Status:</strong> The status affects how your prizes are calculated.
            </p>
            <h3 className="en" style={{ textAlign: 'left' }}>5. Scoring and Group Allocation</h3>
            <p className="en">
                <strong>Collecting Points:</strong> Points are collected based on dice rolls.
            </p>
            <p className="en">
                <strong>Group Allocation:</strong> After two rolls, you are allocated to a group based on your total score:
            </p>
            <ul className="en">
                <li>Group 1: High scores (11-12)</li>
                <li>Group 2: Medium scores (7-10)</li>
                <li>Group 3: Low scores (2-6)</li>
            </ul>
            <h3 className="en" style={{ textAlign: 'left' }}>6. Winning Prizes</h3>
            <p className="en">
                <strong>Prize Distribution:</strong> Prizes are distributed based on your group.
            </p>
            <p className="en">
                <strong>Withdrawing Prizes:</strong> You can withdraw your prizes after rolling, but only after a specified time has passed.
            </p>
            <p className="en">
                <strong>Losing Prizes:</strong> Unwithdrawn prizes reset if not claimed within the specified time.
            </p>
            <h3 className="en" style={{ textAlign: 'left' }}>7. Game Events</h3>
            <p className="en">
                The game records important actions through events, such as game start, user entry, dice creation, and prize distribution. These help you track your progress and actions within the game.
            </p>
            <h3 className="en" style={{ textAlign: 'left' }}>8. Important Notes</h3>
            <p className="en">
                <strong>Design and Development:</strong> This game is individually designed and developed. Supporting it helps in its improvement and future projects.
            </p>
            <p className="en">
                <strong>Costs:</strong> 10% of entry fees cover operational costs, including network fees, deployment, updates, and personal expenses.
            </p>
            <p className="en">
                <strong>Random Nature:</strong> This is a game of chance. Factors like player numbers and timing can affect outcomes.
            </p>
            <p className="en">
                <strong>Age Restriction:</strong> Players under 18 should not participate.
            </p>
            <p className="en">
                <strong>Wallet Responsibility:</strong> You are solely responsible for your wallet security. We do not have access to your wallet or store personal information.
            </p>
            <h3 className="en" style={{ textAlign: 'left' }}>Rule Updates</h3>
            <p className="en">
                Game rules and mechanisms may be updated over time, with changes communicated to players.
            </p>
            <h3 className="en" style={{ textAlign: 'left' }}>Disclaimer</h3>
            <p className="en">
                This is a recreational project with no financial guarantees. Players are responsible for their transactions. Unclaimed prizes return to the group pool for redistribution.
            </p>
            <h3 className="en" style={{ textAlign: 'left' }}>Contract Transparency</h3>
            <p className="en">
                The smart contract is open-source and available for review to ensure transparency at this address (https://bscscan.com/address/0x0B9dA018A83A4BacE855655e103c3b50109426e9#code).
            </p>
            <h3 className="en" style={{ textAlign: 'left' }}>Transaction Fees</h3>
            <p className="en">
                Each transaction incurs a small fee, determined by the blockchain network.
            </p>
            <h3 className="en" style={{ textAlign: 'left' }}>Conclusion</h3>
            <p className="en">
                By following this guide, you are now familiar with the rules and limitations of the Dice Rolling Game. We aim to provide a transparent and secure experience. Enjoy the game and good luck! If you have any questions, please contact our support team.
            </p>
            <p className="en">
                This guide has been formatted for clarity and accuracy, ensuring that all rules align with the smart contract's logic.
            </p>
        </>
    );

    const persianContent = (
        <>
            <h2 className="fa">راهنمای کاربر برای بازی پرتاب تاس</h2>
            <p className="fa">
                به بازی پرتاب تاس خوش آمدید! این راهنما به شما کمک می‌کند تا قوانین، محدودیت‌ها و مکانیک‌های بازی را درک کنید. با دنبال کردن این راهنما، می‌توانید تجربه بازی خود را با اطمینان بیشتری لذت ببرید.
            </p>
            <p className="fa">
                شما می‌توانید بازی را در مدت دو تا ۶ روز کامل کنید. این بستگی به عملکرد شما در بازی دارد.
            </p>
            <p className="fa" style={{ fontWeight: 'bold', color: 'red', fontSize: '1.2em' }}>
                توجه
            </p>
            <p className="fa">
                توصیه ما این است که یک کیف پول جدید ایجاد کنید تا از بازی لذت ببرید و با ارسال مقدار لازم BNB به آن کیف پول شرکت کنید. این برای دارایی‌های شما امن‌تر است.
            </p>
            <p className="fa">
                برای پیوستن به بازی باید یک کیف پول رمزارز (مانند متاماسک، تراست ولت و غیره) نصب کنید.
            </p>
            <p className="fa">
                برای پیوستن به بازی در دستگاه‌های اندروید و iOS، می‌توانید در مرورگر داخلی کیف پول خود (مانند متاماسک، تراست ولت و غیره) به www.lottoariya.xyz بروید و در بازی شرکت کنید.
            </p>
            <p className="fa">
                شما باید حدود ۰.۰۰۶ BNB در کیف پول خود داشته باشید تا بتوانید در بازی شرکت کنید و هزینه‌های تراکنش را پوشش دهید.
            </p>
            <p className="fa">
                اگر ظرف یک روز پس از ورود به بازی و پرداخت ۰.۰۰۴ BNB تاس خود را ایجاد نکنید، از بازی حذف خواهید شد.
            </p>
            <p className="fa">
                اگر ظرف ۵ روز پس از ایجاد تاس خود از آن استفاده نکنید، از بازی حذف خواهید شد.
            </p>
            <p className="fa">
                اگر ظرف یک روز پس از پرتاب تاس و تعیین جایزه، جایزه خود را برداشت نکنید، از بازی حذف خواهید شد.
            </p>
            <h3 className="fa">۱. پیوستن به بازی</h3>
            <p className="fa">
                <strong>هزینه ورود:</strong> برای شرکت در بازی، باید ۰.۰۰۴ BNB پرداخت کنید.
            </p>
            <p className="fa">
                <strong>محدودیت شرکت‌کنندگان:</strong> بازی می‌تواند حداکثر ۱۵۰۰ شرکت‌کننده داشته باشد. اگر این محدودیت رسید، نمی‌توانید تا زمانی که یک جای خالی ایجاد شود، بپیوندید.
            </p>
            <p className="fa">
                <strong>پیوستن یک‌بار:</strong> هر کاربر فقط می‌تواند یک بار در هر جلسه بازی شرکت کند. اگر قبلاً پیوسته‌اید، باید منتظر جلسه بعدی باشید.
            </p>
            <h3 className="fa">۲. ایجاد تاس</h3>
            <p className="fa">
                <strong>زمان ایجاد:</strong> پس از پیوستن، شما ۱ روز برای ایجاد تاس خود دارید.
            </p>
            <p className="fa">
                <strong>تاس قبلی:</strong> شما نمی‌توانید تا زمانی که تاس فعلی در یک پرتاب استفاده نشده است، تاس جدیدی ایجاد کنید.
            </p>
            <h3 className="fa">۳. پرتاب تاس</h3>
            <p className="fa">
                <strong>زمان پرتاب:</strong> شما می‌توانید تاس خود را ظرف ۵ روز پس از ایجاد آن پرتاب کنید.
            </p>
            <p className="fa">
                <strong>انتظار قبل از پرتاب:</strong> شما باید حداقل ۱ روز پس از ایجاد تاس قبل از پرتاب آن صبر کنید.
            </p>
            <p className="fa">
                <strong>تعداد پرتاب‌ها:</strong> شما می‌توانید تاس خود را دو بار پرتاب کنید. هر پرتاب یک نمره تصادفی بین ۱ تا ۶ تولید می‌کند.
            </p>
            <h3 className="fa">۴. وضعیت تاس</h3>
            <p className="fa">
                <strong>تاس عادی:</strong> وضعیت اولیه هنگام ایجاد تاس شما.
            </p>
            <p className="fa">
                <strong>تاس نقره‌ای:</strong> اگر ۲ روز بدون پرتاب صبر کنید، تاس شما نقره‌ای می‌شود.
            </p>
            <p className="fa">
                <strong>تاس طلایی:</strong> اگر ۲ روز دیگر پس از نقره‌ای شدن صبر کنید، تاس شما طلایی می‌شود.
            </p>
            <p className="fa">
                <strong>تأثیر وضعیت تاس:</strong> وضعیت بر نحوه محاسبه جوایز شما تأثیر می‌گذارد.
            </p>
            <h3 className="fa">۵. امتیازدهی و تخصیص گروه</h3>
            <p className="fa">
                <strong>جمع‌آوری امتیاز:</strong> امتیازها بر اساس پرتاب‌های تاس جمع‌آوری می‌شوند.
            </p>
            <p className="fa">
                <strong>تخصیص گروه:</strong> پس از دو پرتاب، شما بر اساس نمره کلی خود به یک گروه تخصیص داده می‌شوید:
            </p>
            <ul className="fa">
                <li>گروه ۱: نمرات بالا (۱۱-۱۲)</li>
                <li>گروه ۲: نمرات متوسط (۷-۱۰)</li>
                <li>گروه ۳: نمرات پایین (۲-۶)</li>
            </ul>
            <h3 className="fa">۶. برنده شدن جوایز</h3>
            <p className="fa">
                <strong>توزیع جوایز:</strong> جوایز بر اساس گروه شما توزیع می‌شوند.
            </p>
            <p className="fa">
                <strong>برداشت جوایز:</strong> شما می‌توانید جوایز خود را پس از پرتاب برداشت کنید، اما فقط پس از گذشت زمان مشخصی.
            </p>
            <p className="fa">
                <strong>از دست دادن جوایز:</strong> جوایز بدون برداشت در صورت عدم ادعا در مدت زمان مشخصی بازنشانی می‌شوند.
            </p>
            <h3 className="fa">۷. رویدادهای بازی</h3>
            <p className="fa">
                بازی اقدام‌های مهم را از طریق رویدادها ثبت می‌کند، مانند شروع بازی، ورود کاربر، ایجاد تاس و توزیع جوایز. این به شما کمک می‌کند تا پیشرفت و اقدام‌های خود را در بازی پیگیری کنید.
            </p>
            <h3 className="fa">۸. نکات مهم</h3>
            <p className="fa">
                <strong>طراحی و توسعه:</strong> این بازی به صورت فردی طراحی و توسعه داده شده است. حمایت از آن به بهبود و پروژه‌های آینده کمک می‌کند.
            </p>
            <p className="fa">
                <strong>هزینه‌ها:</strong> ۱۰٪ از هزینه‌های ورودی هزینه‌های عملیاتی را پوشش می‌دهد، از جمله هزینه‌های شبکه، استقرار، به‌روزرسانی‌ها و هزینه‌های شخصی.
            </p>
            <p className="fa">
                <strong>طبیعت تصادفی:</strong> این یک بازی شانس است. عواملی مانند تعداد بازیکنان و زمان می‌توانند بر نتایج تأثیر بگذارند.
            </p>
            <p className="fa">
                <strong>محدودیت سنی:</strong> بازیکنان زیر ۱۸ سال نباید شرکت کنند.
            </p>
            <p className="fa">
                <strong>مسئولیت کیف پول:</strong> شما به تنهایی مسئول امنیت کیف پول خود هستید. ما به کیف پول شما دسترسی نداریم و اطلاعات شخصی را ذخیره نمی‌کنیم.
            </p>
            <h3 className="fa">به‌روزرسانی قوانین</h3>
            <p className="fa">
                قوانین و مکانیک‌های بازی ممکن است در طول زمان به‌روزرسانی شوند و تغییرات به بازیکنان اطلاع‌رسانی می‌شود.
            </p>
            <h3 className="fa">سلب مسئولیت</h3>
            <p className="fa">
                این یک پروژه تفریحی است و هیچ تضمینی مالی ندارد. بازیکنان مسئول تراکنش‌های خود هستند. جوایز بدون ادعا به استخر گروهی برای توزیع مجدد بازمی‌گردند.
            </p>
            <h3 className="fa">شفافیت قرارداد</h3>
            <p className="fa">
                قرارداد هوشمند منبع باز است و برای بررسی در دسترس است تا شفافیت را در این آدرس (https://bscscan.com/address/0x0B9dA018A83A4BacE855655e103c3b50109426e9#code) تضمین کند.
            </p>
            <h3 className="fa">هزینه‌های تراکنش</h3>
            <p className="fa">
                هر تراکنش هزینه کمی دارد که توسط شبکه بلاکچین تعیین می‌شود.
            </p>
            <h3 className="fa">نتیجه‌گیری</h3>
            <p className="fa">
                با دنبال کردن این راهنما، اکنون با قوانین و محدودیت‌های بازی پرتاب تاس آشنا هستید. ما هدف داریم که تجربه‌ای شفاف و امن ارائه دهیم. از بازی لذت ببرید و موفق باشید! اگر سوالی دارید، لطفاً با تیم پشتیبانی ما تماس بگیرید.
            </p>
            <p className="fa">
                این راهنما برای وضوح و دقت فرمت شده است و اطمینان حاصل می‌کند که تمام قوانین با منطق قرارداد هوشمند هم‌راستا هستند.
            </p>
        </>
    );

    return (
        <div className="how-to-play-modal" ref={ref}>
            <button className="close-button" onClick={onClose}>Close</button>
            <div className="language-selector">
                <button onClick={() => setLanguage('en')} className={language === 'en' ? 'active' : ''}>English</button>
                <button onClick={() => setLanguage('fa')} className={language === 'fa' ? 'active' : ''}>فارسی</button>
            </div>
            {language === 'en' ? englishContent : persianContent}
        </div>
    );
});

export default HowToPlay;